import { languageEn, languageRu } from './words';

interface IItems {
  text: string;
  span: string;
  sum: string;
}

interface IGifts {
  path: string;
  main: string;
  text: string;
}
interface ILinks {
  path: string;
  word: string;
}
interface IStaticWords {
  links: ILinks[];
  logoHead: string;
  logoHeadSpan: string;
  textHead: string;
  buttonHeadText: string;
  dataItems: IItems[];
  gifts: IGifts[];
  textEmployes: string;
  textEmployesSpan: string;
  textFindPeople: string;
  viewVacancies: string;
  feedBack: string;
  feedBackText: string;
  formName: string;
  formPosition: string;
  formLink: string;
  formButton: string;
  formPrivacy: string;
  formPrivacyMain: string;
  rights: string;
  internsText: string;
  vacanciesPaid: string;
  successSend: string;
  successSendContact: string;
  successSendButton: string;
  privacyTitle: string;
  privacyText1: string;
  privacyText2: string;
  privacyMain1: string;
  privacyLabel1: string;
  privacyLabel2: string;
  privacyMain2: string;
  privacyLi1: string;
  privacyLi2: string;
  privacyMain3: string;
  privacyLabel3: string;
  privacyLabel4: string;
  privacyMain4: string;
  privacyLabel5: string;
  privacyMain5: string;
  privacyLabel6: string;
  privacyLi3: string;
  privacyLi4: string;
  privacyLi5: string;
  privacyLi6: string;
  privacyLi7: string;
  privacyLi8: string;
  privacyLi9: string;
  privacyLi10: string;
  privacyLi11: string;
  privacyMain6: string;
  privacyLabel7: string;
  privacyLabel8: string;
  privacyLi12: string;
  privacyLi13: string;
  privacyLabel9: string;
  privacyMain7: string;
  privacyNameCompany: string;
  privacyLocaleCompany: string;
  closeModal: string;
  applyVacancie: string;
  responsibilities: string;
  requirements: string;
  advantages: string;
  conditions: string;
}

interface ILanguage {
  language: string;
  staticLanguageRu: IStaticWords;
  staticLanguageEn: IStaticWords;
  processLanguage: boolean;
}

export default {
  actions: {},
  mutations: {
    updateLanguage(state: ILanguage, change: string): void {
      if (change === state.language) {
        return;
      }
      state.processLanguage = true;
      state.language = change;
      window.localStorage.setItem('language', change);
      (this as any).commit('setMenu', false, { root: true });
      if (change === 'en') {
        (this as any).dispatch('getAllVacanciesEn', null, {
          root: true,
        });
        (this as any).dispatch('getAllInternsEn', null, { root: true });
      } else {
        (this as any).dispatch('getAllVacancies', null, { root: true });
        (this as any).dispatch('getAllInterns', null, { root: true });
      }
    },
    updateLanguageDefault(state: ILanguage) {
      state.processLanguage = false;
    },
    updateLanguageLoad(state: ILanguage, change: string): void {
      state.language = change;
      window.localStorage.setItem('language', change);
    },
  },
  state: (): ILanguage => ({
    language: window.localStorage.getItem('language') || 'ru',
    staticLanguageRu: {
      ...languageRu,
    },
    staticLanguageEn: {
      ...languageEn,
    },
    processLanguage: false,
  }),
  getters: {
    getLanguage(state: ILanguage): string {
      return state.language;
    },
    getStaticLanguage(state: ILanguage): IStaticWords {
      if (state.language === 'ru') {
        return state.staticLanguageRu;
      }
      return state.staticLanguageEn;
    },
    getProcessLanguage(state: ILanguage): boolean {
      return state.processLanguage;
    },
  },
};
