
import { Head } from '@vueuse/head';
import InternsContent from '../components/InternsContent/InternsContent.vue';
import FeedBack from '../components/FeedBack/FeedBack.vue';

export default {
  components: {
    InternsContent,
    FeedBack,
    Head,
  },
};
