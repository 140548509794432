import { BACKEND_URL } from '../constantURL';

interface IEmployes {
  avatarLink: string;
  name: string;
  workExperience: number;
  position: string;
}

interface IEmployeesDto {
  employees: IEmployes[]
}

interface ICommitType {
  commit(commit: string, payload: IEmployes[]): void;
}

export default {
  actions: {
    async getAllEmployes(ctx: ICommitType): Promise<void> {
      const data = await fetch(`${BACKEND_URL}/employees`);
      const employees: IEmployeesDto = await data.json();
      ctx.commit('updateEmployes', employees.employees);
    },
  },
  mutations: {
    updateEmployes(
      state: { employes: IEmployes[]; processE: boolean },
      newEmployes: IEmployes[]
    ): void {
      state.employes = newEmployes;
      state.processE = false;
    },
  },
  state: (): { employes: IEmployes[]; processE: boolean } => ({
    employes: [],
    processE: true,
  }),
  getters: {
    allEmployes(state: { employes: IEmployes[] }): IEmployes[] {
      return state.employes;
    },
    getProcessE(state: { processE: boolean }): boolean {
      return state.processE;
    },
  },
};
