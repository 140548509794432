import { BACKEND_URL } from '../constantURL';

export interface IVacancies {
  name: string;
  responsibilities: [string];
  requirements: [string];
  advantages: [string];
  conditions: [string];
  active: boolean;
}

export interface IVacanciesDto {
  vacancies: IVacancies[]
}

interface ICommitType {
  commit(commit: string, payload: IVacancies[]): void;
}

export default {
  actions: {
    async getAllVacancies(ctx: ICommitType): Promise<void> {
      const data = await fetch(`${BACKEND_URL}/vacancies?` + new URLSearchParams({
        lang: 'ru'
      }));
      const vacancies: IVacanciesDto = await data.json();
      ctx.commit('updateVacancies', vacancies.vacancies);
      (this as any).commit('updateLanguageDefault', null, { root: true });
    },
    async getAllVacanciesEn(ctx: ICommitType): Promise<void> {
      const data = await fetch(`${BACKEND_URL}/vacancies?` + new URLSearchParams({
        lang: "en"
      }));
      const vacancies: IVacanciesDto = await data.json();
      ctx.commit('updateVacancies', vacancies.vacancies);
      (this as any).commit('updateLanguageDefault', null, { root: true });
    },
  },
  mutations: {
    updateVacancies(
      state: { vacancies: IVacancies[]; processV: boolean },
      newVacancie: IVacancies[]
    ): void {
      state.vacancies = newVacancie;
      state.processV = false;
    },
  },
  state: (): { vacancies: IVacancies[]; processV: boolean } => ({
    vacancies: [],
    processV: true,
  }),
  getters: {
    allVacancies(state: { vacancies: IVacancies[] }): IVacancies[] {
      return state.vacancies;
    },
    activeVacancies(state: { vacancies: IVacancies[] }): IVacancies[] {
      return [...state.vacancies].filter(
        (item: IVacancies) => item.active === true
      );
    },
    getProcessV(state: { processV: boolean }): boolean {
      return state.processV;
    },
  },
};
