
import { defineComponent } from '@vue/runtime-core';
import { mapGetters, mapMutations } from 'vuex';
import HrLinks from '../HrLinks/HrLinks.vue';

export default defineComponent({
  components: {
    HrLinks,
  },
  methods: {
    ...mapMutations(['updateLanguage', 'setMenu']),
    activeHeader(path: string): boolean {
      if (
        path !== '/about-us' &&
        path !== '/vacancies' &&
        path !== '/interns'
      ) {
        return true;
      }
      return false;
    },
  },
  computed: {
    ...mapGetters(['getLanguage', 'getMenu', 'getStaticLanguage']),
  },
});
