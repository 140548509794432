
import { mapGetters } from 'vuex';
import FormVacancies from '../FormVacancies/FormVacancies.vue';
import FeedBackLayout from '../FeedBackLayout/FeedBackLayout.vue';

export default {
  components: {
    FormVacancies,
    FeedBackLayout,
  },
  computed: mapGetters(['getStaticLanguage', 'getLanguage']),
  methods: {
    activeFeed(path: string): boolean {
      if (path === '/contact-us') {
        return true;
      }
      return false;
    },
  },
};
