
import { Head } from '@vueuse/head';
import HeaderAbout from '../components/HeaderAbout/HeaderAbout.vue';
import AdvantagesCompany from '../components/AdvantagesCompany/AdvantagesCompany.vue';
import Gifts from '../components/Gifts/Gifts.vue';
import AboutEmployes from '../components/AboutEmployes/AboutEmployes.vue';
import FindPeople from '@/components/FindPeople/FindPeople.vue';
import FeedBack from '@/components/FeedBack/FeedBack.vue';

export default {
  components: {
    HeaderAbout,
    AdvantagesCompany,
    Gifts,
    AboutEmployes,
    FindPeople,
    FeedBack,
    Head,
  },
};
