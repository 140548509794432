import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_layout_content = _resolveComponent("layout-content")!

  return (_openBlock(), _createBlock(_component_layout_content, {
    textContent: _ctx.getStaticLanguage.internsText,
    items: _ctx.allInterns,
    process: _ctx.getProcessI,
    setVacan: _ctx.setVacan,
    setModal: _ctx.setModal
  }, null, 8, ["textContent", "items", "process", "setVacan", "setModal"]))
}