import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e4e8233"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "feedback__text" }
const _hoisted_3 = { class: "feedback__flex" }
const _hoisted_4 = { class: "about__feedback_text" }
const _hoisted_5 = { class: "form_cont_page" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_feed_back_layout = _resolveComponent("feed-back-layout")!
  const _component_form_vacancies = _resolveComponent("form-vacancies")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      feedback: true,
      active: $options.activeFeed(_ctx.$route.path),
      en: _ctx.getLanguage,
    })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.getStaticLanguage.feedBack), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.getStaticLanguage.feedBackText), 1),
        _createVNode(_component_feed_back_layout, { className: 'about__feedback_info' })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_form_vacancies, { count: 1 }),
        _createVNode(_component_feed_back_layout, { className: 'about__feedback_info_mobile' }),
        _createElementVNode("img", {
          class: "form_cont_page_img",
          src: 
            _ctx.getLanguage === 'ru'
              ? require('../../../public/images/Map.svg')
              : require('../../../public/images/Map_En.svg')
          ,
          alt: "Map"
        }, null, 8, _hoisted_6)
      ]),
      _createElementVNode("footer", {
        class: _normalizeClass({ active: $options.activeFeed(_ctx.$route.path) })
      }, [
        _createElementVNode("p", null, "2021 © " + _toDisplayString(_ctx.getStaticLanguage.rights), 1)
      ], 2)
    ])
  ], 2))
}