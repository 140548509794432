
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { defineComponent } from '@vue/runtime-core';
import Header from './components/Header/Header.vue';
import ModalVacancies from './components/ModalVacancies/ModalVacancies.vue';
import ModalLetter from './components/ModalLetter/ModalLetter.vue';
import ModalPrivacy from './components/ModalPrivacy/ModalPrivacy.vue';

type IBody = HTMLElement;
type ITimer = null | number;
export default defineComponent({
  methods: {
    ...mapActions([
      'getAllVacancies',
      'getAllInterns',
      'getAllEmployes',
      'getAllVacanciesEn',
      'getAllInternsEn',
    ]),
    ...mapMutations([
      'setVacan',
      'setMenu',
      'setTablet',
      'setMobile',
      'updateLanguageLoad',
    ]),
    resingDev(timer?: ITimer, body?: IBody): void {
      if (document.documentElement.clientWidth > 1230 && this.getMenu) {
        this.setMenu(false);
      }
      if (document.documentElement.clientWidth < 768) {
        this.setMobile(true);
      } else {
        this.setMobile(false);
      }
      if (document.documentElement.clientWidth < 1024) {
        this.setTablet(true);
      } else {
        this.setTablet(false);
      }

      if (timer) {
        clearTimeout(timer);
        timer = null;
      } else {
        body!.classList.add('stop-transition');
      }
      timer = setTimeout(() => {
        body!.classList.remove('stop-transition');
        timer = null;
      }, 100);
    },
  },
  computed: mapGetters(['allVacancies', 'getMenu', 'getProcessLanguage']),
  components: {
    Header,
    ModalVacancies,
    ModalLetter,
    ModalPrivacy,
  },
  async mounted(): Promise<void> {
    const language = window.localStorage.getItem('language');
    if (language) {
      this.updateLanguageLoad(language);
    } else {
      window.localStorage.setItem('language', 'ru');
    }
    if (document.documentElement.clientWidth < 1024) {
      this.setTablet(true);
    }
    if (language === 'en') {
      await this.getAllVacanciesEn();
      await this.getAllInternsEn();
    } else {
      await this.getAllVacancies();
      await this.getAllInterns();
    }
    await this.getAllEmployes();
    await this.setVacan(this.allVacancies[2]);
    const { body } = document;
    const timer: ITimer = null;
    window.addEventListener('resize', () => {
      this.resingDev(timer, body);
    });
    if (document.documentElement.clientWidth < 768) {
      this.setMobile(true);
    }
  },
  unmounted(): void {
    window.removeEventListener('resize', () => {
      this.resingDev();
    });
  },
});
