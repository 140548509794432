import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fee8232"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vacancies_cont_block" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  key: 0,
  class: "about__employes_cont"
}
const _hoisted_4 = {
  key: 1,
  class: "preloader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_layout_elements_vacancies = _resolveComponent("layout-elements-vacancies")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString($props.textContent), 1),
      (!$props.process)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_layout_elements_vacancies, {
              items: $props.items,
              setVacan: $props.setVacan,
              setModal: $props.setModal
            }, null, 8, ["items", "setVacan", "setModal"])
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_4))
    ])
  ]))
}