import { createStore } from 'vuex';
import languageModule from '@/modules/languageModule';
import employesModule from '@/modules/employesModule';
import vacanciesModule from '@/modules/vacanciesModule';
import internsModule from '@/modules/internsModule';
import modalModule from '@/modules/modalModule';

export default createStore({
  modules: {
    languageModule,
    employesModule,
    vacanciesModule,
    internsModule,
    modalModule,
  },
});
