
import { Head } from '@vueuse/head';
import FeedBack from '../components/FeedBack/FeedBack.vue';

export default {
  components: {
    FeedBack,
    Head,
  },
};
