import {
  createRouter,
  createWebHistory,
  HistoryState,
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
  RouteRecordRaw,
} from 'vue-router';
import AboutUs from '../views/AboutUs.vue';
import Vacancies from '../views/Vacancies.vue';
import ContactUs from '../views/ContactUs.vue';
import Intern from '../views/Intern.vue';
import NotFound from '../views/NotFound.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Main',
    component: AboutUs,
    redirect: '/about-us',
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs,
  },
  {
    path: '/vacancies',
    name: 'Vacancies',
    component: Vacancies,
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs,
  },
  {
    path: '/interns',
    name: 'Intern',
    component: Intern,
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(
    _to: RouteLocationNormalized,
    _from: RouteLocationNormalizedLoaded,
    savedPosition: HistoryState | null
  ): HistoryState {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
