import { BACKEND_URL } from '../constantURL';

interface IInterns {
  name: string;
  responsibilities: [string];
  requirements: [string];
  advantages: [string];
  conditions: [string];
}

interface IInternsDto {
  interns: IInterns[]
}

interface ICommitType {
  commit(commit: string, payload: IInterns[]): void;
}

export default {
  actions: {
    async getAllInterns(ctx: ICommitType): Promise<void> {
      const data = await fetch(`${BACKEND_URL}/interns?` + new URLSearchParams({
        lang: 'ru'
      }));
      const interns: IInternsDto = await data.json();
      ctx.commit('updateInterns', interns.interns);
      (this as any).commit('updateLanguageDefault', null, { root: true });
    },
    async getAllInternsEn(ctx: ICommitType): Promise<void> {
      const data = await fetch(`${BACKEND_URL}/interns?` + new URLSearchParams({
        lang: 'en'
      }));
      const interns: IInternsDto = await data.json();
      ctx.commit('updateInterns', interns.interns);
      (this as any).commit('updateLanguageDefault', null, { root: true });
    },
  },
  mutations: {
    updateInterns(
      state: { interns: IInterns[]; processI: boolean },
      newIntern: IInterns[]
    ): void {
      state.interns = newIntern;
      state.processI = false;
    },
  },
  state: (): { interns: IInterns[]; processI: boolean } => ({
    interns: [],
    processI: true,
  }),
  getters: {
    allInterns(state: { interns: IInterns[] }): IInterns[] {
      return state.interns;
    },
    getProcessI(state: { processI: boolean }): boolean {
      return state.processI;
    },
  },
};
