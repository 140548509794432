
import { defineComponent } from '@vue/runtime-core';
import { mapGetters, mapMutations } from 'vuex';
import { BACKEND_URL } from '../../constantURL';

interface IState {
  name: string;
  linked: string;
  git: string;
  developer: string;
  privacy: boolean;
  click: boolean;
}

export default defineComponent({
  data(): IState {
    return {
      name: '',
      linked: '',
      git: '',
      developer: '',
      privacy: false,
      click: false,
    };
  },
  computed: mapGetters(['allInterns', 'allVacancies', 'getStaticLanguage']),
  props: ['count'],
  methods: {
    ...mapMutations(['setModal', 'setModalLetter', 'setModalPrivacy']),
    handleEvent(e: MouseEvent): void {
      (e.target as HTMLInputElement).required = true;
      (e.target as HTMLInputElement).nextElementSibling!.id = 'control';
    },
    chooseVarient(e: MouseEvent): void {
      document
        .querySelector('.vacancies__body' + this.count)
        ?.classList.remove('active');
      this.$refs.text.textContent = (e.target as HTMLElement).textContent;
      document
        .querySelectorAll('.vacancies_options' + this.count)
        .forEach((item: Element) => {
          (item as HTMLElement).style.color = '#fff';
        });
      this.developer = this.$refs.text.textContent;
      document
        .querySelector('.vacancies__select' + this.count)
        ?.classList.remove('active');
      (e.target as HTMLElement).style.color = '#3284ff';
      if (!this.$refs.text.textContent.trim()) {
        this.$refs.placeholder.classList.remove('active');
      } else {
        document
          .querySelector('.vacancies__select' + this.count)
          ?.classList.remove('error');
      }
    },
    addSelect(): void {
      document
        .querySelector('.vacancies__body' + this.count)
        ?.classList.toggle('active');
      this.$refs.placeholder.classList.add('active');
      this.click = true;
      document
        .querySelector('.vacancies__select' + this.count)
        ?.classList.toggle('active');
      if (
        !this.$refs.text.textContent.trim() &&
        !document
          .querySelector('.vacancies__body' + this.count)
          ?.classList.contains('active')
      ) {
        this.$refs.placeholder.classList.remove('active');
        document
          .querySelector('.vacancies__select' + this.count)
          ?.classList.add('error');
      } else {
        document
          .querySelector('.vacancies__select' + this.count)
          ?.classList.remove('error');
      }
    },
    async handleSubmit(): Promise<void> {
      await fetch(`${BACKEND_URL}/mailing`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: this.name,
          position: this.developer,
          link: this.linked,
          git: this.git,
        }),
      });
      this.setModal(false);
      this.setModalLetter(true);
      this.name = '';
      this.privacy = false;
      this.linked = '';
      this.git = '';
      this.developer = '';
      this.click = false;
      this.$refs.text.textContent = '';
      this.$refs.placeholder.classList.remove('active');
      document
        .querySelectorAll('.vacancies_input' + this.count)
        .forEach((item: Element) => {
          (item as HTMLInputElement).setCustomValidity('');
          (item as HTMLInputElement).required = false;
        });
      document
        .querySelectorAll('.vacancies_options' + this.count)
        .forEach((item: Element) => {
          (item as HTMLElement).style.color = '#fff';
        });
      document
        .querySelectorAll('.vacancies_label' + this.count)
        .forEach((item: Element) => {
          item.id = '';
        });
    },
    setSelect() {
      document
        .querySelector('.vacancies__body' + this.count)
        ?.classList.remove('active');
      document
        .querySelector('.vacancies__select' + this.count)
        ?.classList.remove('active');
      if (this.$refs.text) {
        if (!this.$refs.text.textContent.trim()) {
          this.$refs.placeholder.classList.remove('active');
        }
      }
      if (this.$refs.text && this.click) {
        if (!this.$refs.text.textContent.trim()) {
          document
            .querySelector('.vacancies__select' + this.count)
            ?.classList.add('error');
        } else {
          document
            .querySelector('.vacancies__select' + this.count)
            ?.classList.remove('error');
        }
      }
    },
  },
  mounted(): void {
    document.body.addEventListener('click', this.setSelect);
  },
  unmounted() {
    document.body.removeEventListener('click', this.setSelect);
  },
});
