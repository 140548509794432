
import { defineComponent } from '@vue/runtime-core';
import { mapGetters, mapMutations } from 'vuex';
import LayoutContent from '../LayoutContent/LayoutContent.vue';

export default defineComponent({
  components: {
    LayoutContent,
  },
  methods: {
    ...mapMutations(['setVacan', 'setModal']),
  },
  computed: mapGetters(['allVacancies', 'getProcessV', 'getStaticLanguage']),
});
