
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { mapGetters } from 'vuex';

gsap.registerPlugin(ScrollTrigger);

interface IRefs {
  scrollCont: HTMLElement;
  spotify: HTMLElement;
}

export default {
  name: 'advantages-company',
  $refs: {} as IRefs,
  computed: mapGetters(['getStaticLanguage']),
  mounted(): void {
    const sections = gsap.utils.toArray('.advantanges__element');
    let maxWidth = 0;
    const getMaxWidth = () => {
      maxWidth = 0;
      sections.forEach((section: unknown): void => {
        maxWidth += (section as HTMLElement).offsetWidth;
      });
    };

    getMaxWidth();

    ScrollTrigger.addEventListener('refreshInit', getMaxWidth);

    gsap.to(sections, {
      x: () => `-${maxWidth - window.innerWidth}`,
      scrollTrigger: {
        trigger: '.advantages-company__container',
        pin: true,
        scrub: true,
        end: () => `+=${maxWidth}`,
        start: 'top top',
        invalidateOnRefresh: true,
      },
    });

    sections.forEach((sct: unknown) => {
      ScrollTrigger.create({
        trigger: sct as HTMLElement,
        start: () =>
          'top top-=' +
          ((sct as HTMLElement).offsetLeft - window.innerWidth / 2) *
            (maxWidth / (maxWidth - window.innerWidth)),
        end: () =>
          '+=' +
          (sct as HTMLElement).offsetWidth *
            (maxWidth / (maxWidth - window.innerWidth)),
        toggleClass: { targets: sct as HTMLElement, className: 'active' },
      });
    });
  },
};
